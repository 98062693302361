<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="row mb-5">
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="from_date">{{$t('inventory_transaction_report.from_date')}}</label>
                            <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="to_date">{{$t('inventory_transaction_report.to_date')}}</label>
                            <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="product">{{$t('product')}}</label>
                            <multiselect id="product" v-model="item"
                                :placeholder="$t('product')"
                                label="name"
                                track-by="id"
                                :options="items"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label>{{$t('items.category')}}</label>
                            <treeselect
                                    :options="categories"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.category_id"
                                    @input="updateValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>

                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="f_status">{{$t('stocks_report.warehouse')}}</label>
                            <!-- <select name="" id="f_status" v-model="filters.inventory_id" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in inventories" :value="row.id" :key="'inv'+index">{{ row.name }}</option>
                            </select> -->
                            <multiselect v-model="inventory"
                                :placeholder="$t('stocks_report.warehouse')"
                                label="name"
                                track-by="id"
                                :options="inventories"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>


                        <div class="form-group d-inline-flex col-md-4 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{$t('display_report')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        
        <!--begin::customer-->
        <div class="card card-custom mt-5">
            
            <div class="card-body">

                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <!-- <div class="col-12 mt-10">
                                <hr>
                            </div> -->
                            <div class="col-12 mt-10" id="summaryTableTitle">
                                <h4 class="text-center">{{ $t('product_average_cost.product_average_cost')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span></h5>
                            </div>
                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <!-- <download-excel
                                            class="dropdown-item"
                                            :fetch="dataReport"
                                            :fields="json_fields"
                                            :name="$t('estimated_inventory.estimated_inventory')+'.xls'">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </download-excel> -->
                                    <button class="dropdown-item" @click="convertTableToExcelReport">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </button>

                                    <button class="dropdown-item" @click="printData('summaryTable')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered text-center" id="summaryTable">
                                        <thead>
                                        <tr >
                                            <th>{{$t('product_average_cost.code')}}</th>
                                            <th>{{$t('product_average_cost.barcode')}}</th>
                                            <th>{{$t('product_average_cost.product_name')}}</th>
                                            <th colspan="2">{{$t('product_average_cost.before_quantity_available_warehouse')}}</th>
                                            <th colspan="2">{{$t('product_average_cost.inward_quantity_entering_warehouses')}}</th>
                                            <th colspan="2">{{$t('product_average_cost.outward_quantity_coming_warehouses')}}</th>
                                            <th colspan="2">{{$t('product_average_cost.next_entrance_exit')}}</th>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{{$t('quantity')}}</td>
                                            <td>{{$t('amount')}}</td>
                                            <td>{{$t('quantity')}}</td>
                                            <td>{{$t('amount')}}</td>
                                            <td>{{$t('quantity')}}</td>
                                            <td>{{$t('amount')}}</td>
                                            <td>{{$t('quantity')}}</td>
                                            <td>{{$t('amount')}}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, index) in data.data" :key="index">
                                                 <td>{{ row.code }}</td> 
                                                 <td>{{ row.barcode }}</td>
                                                 <td>{{ row.product_name }}</td>

                                                 <td>{{ row.before_total }}</td>
                                                 <td>{{ row.before_total_amount }}</td>
                                                 <td>{{ row.inward_total }}</td>
                                                 <td>{{ row.inward_total_amount }}</td>
                                                 <td>{{ row.outward_total }}</td>
                                                 <td>{{ row.outward_total_amount }}</td>
                                                 <td>{{ row.total_transactions }}</td>
                                                 <td>{{ row.total_transactions_amount }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody> 
                                            <tr v-if="data.total">
                                                <td>{{$t('total')}}</td>
                                                <td></td><td></td><td></td>
                                                <td>{{data.total.sum_before_total_amount}}</td>
                                                <td></td>
                                                <td>{{data.total.sum_inward_total_amount}}</td>
                                                <td></td>
                                                <td>{{data.total.sum_outward_total_amount}}</td>
                                                <td></td>
                                                <td>{{data.total.sum_total_transactions_amount}}</td>
                                            </tr>
                                        </tbody> 
                                    </table>
                                </div>

                            </div>
                            <div class="col-12 text-center" v-if="page">
                                <button class="btn btn-warning" @click="loadMore">
                                    <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Filter -->

            </div>
        </div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";

    export default {
        name: "index-inventory-transaction-report",
        data() {
            return {
                mainRoute: '/reports/product-average-cost-report',
                mainRouteDependency: 'base/dependency',
                items: [],
                inventories: [],
                data: [],
                categories : [],

                filters: {
                    item_id: null,
                    category_id: null,
                    inventory_id: null,
                    from_date: null,
                    to_date: null,
                },
                brand_id: null,
                item: null,
                inventory: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        computed: {

            // json_fields: function () {
            //     let fields = {};
            //     fields[this.$t('estimated_inventory.code')] = 'code';
            //     fields[this.$t('estimated_inventory.product_name')] = 'product_name';
            //     fields[this.$t('estimated_inventory.qty')] = 'qty';
            //     fields[this.$t('estimated_inventory.current_retail_price')] = 'current_retail_price';
            //     fields[this.$t('estimated_inventory.average_retail_price')] = 'average_retail_price';
            //     fields[this.$t('estimated_inventory.expected_total_retail_price')] = 'expected_total_retail_price';
            //     fields[this.$t('estimated_inventory.total_buy_price')] = 'total_buy_price';
            //     fields[this.$t('estimated_inventory.expected_profit')] = 'expected_profit';

            //     return fields;
            // },


        },
        watch: {
            item: function (val) {
                if (val && val.id) {
                    this.filters.item_id = val.id;
                } else {
                    this.filters.item_id = null;
                }
            },
            inventory: function (val) {
                if (val && val.id) {
                    this.filters.inventory_id = val.id;
                } else {
                    this.filters.inventory_id = null;
                }
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"),route:'/store/reports'}, {title: this.$t("MENU.product_average_cost")}]);

            this.getItems();
            this.getCategories();
            this.getInventories();
        },
        methods: {
            convertTableToExcelReport() {
                TableToExcel.convert(document.getElementById("summaryTable"), {
                    name: this.$t('product_average_cost.xlsx'),
                    sheet: {
                        name: 'Sheet 1' 
                    }
                });
            },

            doFilter() {
                this.page = 1;
                this.getData();
            },
            resetFilter() {
                
                this.filters.item_id= null;
                this.item = null;
                this.inventory = null;
                this.filters.category_id= null;
                this.filters.inventory_id= null;
                this.filters.from_date= null;
                this.filters.to_date= null;
                
                this.doFilter();

            },
            dataReport() {
                this.setReportLog('excel', 'product average cost');
                return this.data;
            },
            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data;
                });
            },
            updateValue(value) {
                this.filters.category_id = value
            },
            loadOptions() {},
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
                    this.categories = response.data.data;
                });
            },
            getItems() {
                ApiService.get(this.mainRouteDependency + "/items").then((response) => {
                    this.items = response.data.data;
                });
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            printData(tableId) {
                this.setReportLog('pdf', 'product average cost');

                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
